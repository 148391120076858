:root {
  --lightBlueBgc: rgba(201, 218, 229, 1);
  --lightBlueBgcHover: rgba(201, 218, 229, .6);
}

.navigation-container {
  justify-content: space-between;
  padding: 100px 0 0;

  @media (max-width: 768px){
    .two-cols&{
      display: none;
    }
  }

  &.two-cols {
    @media(min-width: 1025px) {
      display: none;
    }
  }

  a {
    flex-basis: 49.75%;
  }

  .navigation-block {
    padding-bottom: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 7.4%;
    background: var(--lightBlueBgc)
      url('../../assets/images/arrow.svg')
      no-repeat
      center calc(100% - 38px);
    transition: background .3s ease-out;

    &:hover {
      background: var(--lightBlueBgcHover)
        url('../../assets/images/arrow.svg')
        no-repeat
        52% calc(100% - 38px);
    }

    @media (max-width: 1024px){
      height: 25vw;
    }

    @media (max-width: 767px) {
      height: 41.875vw;
      margin-bottom: 2%;
      padding-top: 10%;
      background-size: 21%;
    }

    .navigation-block_title,
    .navigation-block_text {
      width: 72.5%;
      margin: 0 auto;

      @media (max-width: 1366px) {
        width: 90%;
      }

      @media (max-width: 1024px) {
        width: 100%;
      }
    }

    .navigation-block_title {
      font-weight: 300;
      font-size: 28px;
      line-height: 1.2857;
      height: 3.6rem;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media (max-width: 1100px) {
        font-size: 25px;
        line-height: 30px;
      }
    }

    .navigation-block_text {
      font-family: 'Libre Baskerville', serif;
      font-size: 22px;
      line-height: 1.75;
      letter-spacing: 1px;
      padding: 1.4% 0 0;
      color: #000000;

      @media (max-width: 1100px) {
        padding-top: 20px;
        font-size: 20px;
        line-height: 22px;
      }
    }
  }
}