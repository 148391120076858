:root {
  --mainColor: #2b5266;
  --whiteBgc: #ffffff;
  --lightBlue: #c9dae5;
  --darkerBlue: #2b5266;
  --lightGrey: #9d9d9c;
  --blueSky: #7693a3;
  --sectionLeftPadding: 6.945%;
}

@import "../commons/paragraphs.css";

.solutions {
  counter-reset: title-counter;
  .page-title{
    .mobile-full-width{
      @media (max-width: 768px){
        display: block;
        text-align: center;
      }
    }
  }
  .intro-visual {
    position: relative;
    z-index: 2;

    @media (min-width: 1025px) {
      background-color: white;
    }

    header {
      margin: 58px 0;
      .page-title {
        margin: 0;
      }
    }
  }

  .solutions-header {
    margin-bottom: 50px;
    /* padding-left: var(--sectionLeftPadding); */

    .section-title {
      counter-increment: title-counter;
      margin-bottom: 1.59%;
      width: 320px;
      z-index: 0;
      position: relative;

      &::before {
        content: "" counter(title-counter);
        position: absolute;
        z-index: -1;
        top: 33%;
        left: 67%;
        font-family: "Libre Baskerville";
        font-weight: 400;
        font-style: normal;
        font-size: 240px;
        line-height: 0.21875;
        letter-spacing: 1px;
        color: rgba(201, 218, 229, 0.5);
      }

      @media (max-width: 1240px) {
        width: 300px;
        margin: 0 auto 25px 0;
        font-size: 28px;
        line-height: 30px;
        &::before {
          left: 39%;
          font-size: 200px;
          line-height: 0.6;
          top: -8%;
          /* left: 17%; */
        }
      }
      @media (max-width: 1023px) {
        &::before {
          top: -22%;
          left: 52%;
          font-size: 170px;
          line-height: 0.6;
        }
      }
      @media (max-width: 767px) {
        width: 180px;
        font-size: 24px;
        margin-left: 0;
        margin-bottom: 50px !important;
        &::before {
          font-size: 100px;
          top: -10%;
          left: 75%;
        }
      }
        @media (max-width: 767px) {
      }
    }

    p {
      text-align: left;
      width: 100%;
      margin-left: 0;
    }
  }

  @media (max-width: 767px) {
    .two-cols .text-container {
      width: 84.375%;
      margin: 0 auto;
      padding: 0;
    }
    .two-cols {
      .img-container {
      }
      &.two-cols_img-right .img-container {
        margin-left: auto;
      }
      &.two-cols_img-left .img-container {
        margin-right: auto;
      }
    }
  }
}

.solutions {
  .investment-solutions {
    @media (min-width: 1025px) {
      .content {
        background-color: rgba(255, 255, 255, 1);
      }

      .solutions-header,
      .slider-arrows,
      .tabs-list,
      .text-container,
      .tips {
        transition: all 0.3s ease-out;
      }

      &.in {
        .solutions-header,
        .slider-arrows,
        .tabs-list,
        .text-container,
        .tips {
          opacity: 1;
          transform: translate3d(0, 0, 0);
        }

        .solutions-header {
          transition-delay: 0.3s;
        }
        .slider-arrows {
          transition-delay: 0.4s;
        }
        .tabs-list {
          transition-delay: 0.5s;
        }
        .text-container {
          transition-delay: 0.6s;
        }
        .tips {
          transition-delay: 0.7s;
        }

        &.out {
          background-color: rgba(255, 255, 255, 0);
          transition: background-color 0.4s ease-out 0.4s;

          .solutions-header,
          .slider-arrows,
          .tabs-list,
          .text-container,
          .tips {
            opacity: 0;
            transform: translate3d(0, -100px, 0);
            transition: all 0.2s ease-out;
          }

          .slides-list {
            overflow: visible;
          }

          .solutions-header {
            transition-delay: 0.1s;
          }
          .slider-arrows {
            transition-delay: 0.2s;
          }
          .tabs-list {
            transition-delay: 0.2s;
          }
          .text-container {
            transition-delay: 0.2s;
          }
          .tips {
            transition-delay: 0.2s;
          }
        }
      }
    }
    @media (max-width: 1024px) {
      height: auto;
      min-height: 1300px;
    }
    @media (max-width: 1023px) {
      padding-left: 20px;
      padding-right: 20px;
      min-height: unset;
    }
    .slider-container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      /* padding: 0 6.25%; */

      @media (max-width: 767px) {
        display: block;
      }
    }

    .slider-container_left-panel {
      display: flex;
      flex-direction: column;
      flex-basis: 24%;

      @media (max-width: 1280px) {
        flex-basis: 27%;
      }
      @media (max-width: 1023px) {
        flex-basis: 28%;
      }
      @media (max-width: 767px) {
        display: none;
      }

      .tabs-list {
        margin: 0 auto;
        position: sticky;
        top: 126px;

        @media (max-width: 767px) {
          display: none;
        }
        .tab-item {
          position: relative;
          flex-basis: 30.43%;
          text-transform: uppercase;

          color: var(--lightBlue);
          border-bottom: 2px solid var(--lightBlue);
          font-size: 13px;
          letter-spacing: 0.75px;

          &.active {
            color: var(--darkerBlue);
            font-weight: 600;
            border-bottom-color: var(--darkerBlue);
          }

          a {
            display: block;
            padding: 7.25% 0 3.625%;
          }
        }
      }
    }

    .slides {
      flex-basis: 74%;
      position: relative;

      @media (max-width: 1280px) {
        flex-basis: 73%;
      }

      .background {
        position: absolute;
        overflow: hidden;
        z-index: -5;
        top: 0;
      }

      .slides-list {
        @media (min-width: 1025px) {
          height: 100%;
          position: relative;
        }

        @media (max-width: 767px) {
          padding-bottom: 0;
        }
      }

      .slide-item {
        display: none;
        &.active {
          position: relative;
          display: flex;
          justify-content: space-between;
          width: 100%;
          left: 0;
          top: 0;
        }

        @media (max-width: 1024px) {
          position: static;
        }
        @media (max-width: 1023px) {
          flex-wrap: wrap;
        }

        .slide-title {
          flex-basis: 54.805%;
          padding-left: 10%;
          h2 {
            font-weight: 400;
            font-size: 1.6rem;
            line-height: 2rem;
          }
        }
        .slide-text {
          flex-basis: 45.195%;
          padding-left: 4.54%;
          p {
            font-family: "Libre Baskerville";
            font-size: 18px;
            line-height: 1.75;
            color: #000;
            letter-spacing: 1px;
          }
        }
        .text-container {
          flex-basis: 54%;
          margin-left: 4%;

          /* .mask {
            @media (min-width: 1025px) {
              overflow: hidden;
            }
          } */

          .text-title {
            padding-bottom: 35px;
            margin-bottom: 0;
          }

          .equity {
            margin-top: 14%;
            padding: 7% 7% 3%;
            border: 7px solid var(--lightBlue);
            .text-subtitle,
            .text-paragraph {
              font-size: 0.8rem;
              line-height: 1.5625;
              color: var(--darkerBlue);
            }
            .text-subtitle {
              margin-bottom: 20px;
            }
            .text-paragraph:last-of-type {
              font-style: italic;
            }
          }

          .scrollable {
            @media (min-width: 1025px) {
              padding-bottom: 20px;
            }
          }
          @media (max-width: 1023px) {
            flex-basis: 100%;
          }
        }
        .tips {
          position: absolute;
          width: 30%;
          right: 0;
          background-color: #f9f9f9;
          padding: 3% 2% 1%;

          &.tips_empty {
            background-color: rgba(0, 0, 0, 0);
          }

          @media (min-width: 1025px) {
            padding: 0;
            background-color: transparent;
            background-color: #f9f9f9;
            padding: 23px 16px 8px;

            &.tips_empty {
              .scrollable {
                background-color: transparent;
              }
            }

            .scrollable {
              background-color: #f9f9f9;
            }
          }

          @media (max-width: 1024px) {
            position: static;
            width: 38%;
          }
          @media (max-width: 1023px) {
            width: 96%;
            margin-left: 4%;
          }

          .text-title {
            font-size: 18px;
            line-height: 22px;
            font-weight: 400;
            margin-bottom: 25px;
          }
          .subtitle,
          .text-paragraph {
            color: var(--mainColor);
            font-family: "Libre Baskerville", serif;
            font-size: 12px;
            line-height: 25px;

            @media (max-width: 1100px) {
              font-size: 11px;
              line-height: 21px;
            }

            @media (min-width: 1700px) {
              font-size: 13px;
            }

            &.no-bottom-margin {
              margin-bottom: 0;
            }

            &.small {
              font-size: 14px;
              line-height: 25px;
            }
          }
          .subtitle {
            font-weight: 600;
            text-transform: none;
          }
        }
      }
      @media (max-width: 1024px) {
        flex-basis: 70%;
      }
      @media (max-width: 767px) {
        .slide-item {
          display: block !important;
          position: relative;
          margin-bottom: 20px;
          transition: height 0.75s ease-in;
          overflow: hidden;
          max-height: 85px;
          &:first-of-type {
            max-height: 65px;
          }

          .mask {
            opacity: 0;
          }

          &::after {
            content: "";
            display: block;
            position: absolute;
            bottom: 50%;
            right: 0;
            width: 30px;
            height: 20px;
            background-image: url("../../assets/images/previous-arrow.svg");
            background-size: contain;
            background-repeat: no-repeat;
            transform: rotate(-90deg);
            transition: transform 0.75s ease-in;
          }

          &.mobActive {
            max-height: 300vh;
            padding-bottom: 20px;
            transition: max-height 0.75s ease-out;
            .mask {
              opacity: 1;
            }
            &::after {
              transform: rotate(90deg);
              bottom: -1%;
              transition: transform 0.75s ease-out;
            }
          }

          &:not(:first-of-type) {
            border-top: 2px solid var(--darkerBlue);
          }
          .text-container {
            margin-left: 0;
            margin-bottom: 15px;
            .text-title {
              font-size: 16px;
              width: 85%;
              padding: 15px 0 25px;
              background-color: #ffffff;
              color: var(--blueSky);
            }
            .text-subtitle{
              font-size: 12px;
              line-height: 22px;
            }
          }
          .tips {
            position: relative;
            width: 100%;
            margin-left: 0;
          }
        }
      }
    }

    .slider-arrows {
      width: 46%;
      /* margin-bottom: 18%; */
      margin-left: 26%;
      display: flex;
      justify-content: space-between;
      /* flex-direction: column; */

      .slider-arrow {
        margin: 8px 0;
        text-align: center;
        opacity: 1;
        transition: opacity 0.2s ease-out;

        &.hide {
          opacity: 0;
          pointer-events: none;
        }

        p {
          font-family: "Montserrat", sans-serif;
          color: var(--lightGrey);
          font-size: 9px;
          line-height: 1;
          text-transform: uppercase;
          text-align: center;
        }

        img {
          width: 15px;
          height: 28px;
          margin-bottom: 11px;
        }
      }
      @media (max-width: 1023px){
        width: 71%;
        margin-left: auto;
      }
      @media (max-width: 767px){
        display: none;
      }
    }
  }

  .financing-solutions,
  .transmission-solutions,
  .insurance-solutions {
    .two-cols {
      padding: 0;
      justify-content: space-between;
    }
    .text-container {
      .solutions-header {
        padding-top: 43px;
        @media (max-width: 1240px){
          padding-top: 33px;
        }
        @media (max-width: 1024px){
          padding-top: 40px;
        }
        @media (max-width: 767px){
          padding-top: 0;
        }
      }
      &-paragraph {
        margin-bottom: 5%;
      }
    }
    /* .img-container {
      .align-bottom {
        figure {
          picture {
            width: 50vw;
          }
        }
      }
    } */

    .solutions-header {
      p {
        @media (max-width: 1400px) and (min-width: 1025px) {
          width: 100%;
        }
      }
    }
  }

  .financing-solutions,
  .insurance-solutions {
    .two-cols {
      @media (max-width: 767px) {
        padding-left: 0;
      }
    }
  }

  .financing-solutions,
  .insurance-solutions {
    .text-container {
      @media (min-width: 1025px) {
        padding: 0 10% 30px 0;
      }
    }
  }

  .transmission-solutions {
    .two-cols {
      @media (max-width: 767px) {
        padding-right: 0;
        .img-container.onMobile {
          figure {
            margin-left: -22%;
          }
          & + .text-title {
            margin-top: 25px;
          }
        }
      }
      .img-container_tansmission {
        &_desktop {
          @media (max-width: 767px) {
            display: none;
          }
        }
        &_mobile {
          display: none;
          @media (max-width: 767px) {
            position: relative;
            left: -8.25vw;
            display: block;
            margin-bottom: 25px;
          }
        }
      }
      .text-container  {
        @media (min-width: 1025px) {
          padding: 0 0 30px 5%;
        }
        @media (max-width: 480px) {
          h3.text-subtitle {
            font-size: 10px;
            line-height: 24px;
          }
        }
      }
    }
  }
}

.solutions{
  .ie{
    .intro-visual {
      .mask .image-container picture img{
        height: 150%;
        margin-top: -8%;
      }
    }
    .financing-solutions{
      @media (min-width: 769px){
        padding-top: 150px;
      }
      .text-container{
        @media (min-width: 769px){
          position: relative;
          top: -33px;
        }
      }
    }
    .transmission-solutions{
      @media (min-width: 769px){
        padding-top: 150px;
      }
      .text-container{
        @media (min-width: 769px){
          position: relative;
          top: -20px;
        }
      }
    }
    .insurance-solutions{
      @media (min-width: 769px){
        padding-top: 150px;
      }
      .text-container{
        @media (min-width: 769px){
          position: relative;
          top: -20px;
        }
      }
    }
  }
}