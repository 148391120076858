:root {
  --lightGrey: #9D9D9C;
  --lightBlue: #F5F5F7;
}

.bordered-left-right {
  border-left: 1px solid var(--lightGrey);
  border-right: 1px solid var(--lightGrey);
}
.bordered-left {
  border-left: 1px solid var(--lightGrey);
}
.bordered-right {
  border-right: 1px solid var(--lightGrey);
}
.bordered-bottom {
  border-bottom: 1px solid var(--lightGrey);
}
.nav-mobile .bordered-bottom {
  border-bottom-color: var(--lightBlue);
}
