#form-container {
  --mainColor: #2B5266;
  --mustard: #E5BE77;
  &.show {
    display: block;
    opacity: 1;
    transition: opacity 0.4s ease-out;
    pointer-events: all;
  }
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  overflow-x:hidden;
  z-index: 99;
  background-color: #FFFFFF;
  pointer-events: none;

  @media (max-width: 1024px){
    overflow: scroll;
  }
  section {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 65%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 115px;
    opacity: 1;
    z-index: 2;
    transition: opacity 0.3s ease-out;
    &.hide{
      opacity: 0;
      z-index: -1;
      transition: opacity 0.3s ease-in;
    }
    &.form.hide{
      display: none;
    }
    @media (max-width: 1024px){
      padding-top: 60px;
      width: 100%;
      overflow: hidden;
    }
    .form-section-title,
    .form-btn {
      color: var(--mustard);
      text-align: center;
      text-transform: uppercase;

      &:disabled {
        opacity: 0.5;
      }
    }
    .form-section-title {
      font-size: 44px;
      line-height: 56px;
      font-weight: 300;
      margin-bottom: 55px;
      @media (max-width: 1024px){
        font-size: 22px;
        line-height: 32px;
        margin-bottom: 30px;
      }
    }
    &.form{
      @media (max-width: 1024px){
        bottom: unset;
        padding-bottom: 60px;
      }
      .mdc-line-ripple {
        background-color: var(--mainColor) !important;
      }
      .mdc-text-field{
        .mdc-floating-label {
          left: 1px; /*override left position*/
        }
        &__input {
          caret-color: var(--mainColor);
        }
        &::before, &::after {
          background-color: rgba(0, 0, 0, 0.37);
        }
      }

      .mdc-text-field__input,
      .mdc-floating-label,
      .mdc-typography--subtitle1 {
        font-family: 'Montserrat';
        font-size: 13px;
        text-transform: uppercase;
        @media (max-width: 1024px){
          font-size: 11px;
          line-height: 14px;
        }
      }
      .mdc-typography--subtitle1 {
        font-weight: 400;
        margin-bottom: 10px;
        @media (max-width: 1024px) {
          font-size: 12px;
        }
      }
      .mdc-floating-label {
        font-weight: 500;
        color: var(--mainColor);
      }
      .mdc-select .mdc-floating-label--float-above {
        @media (max-width: 1024px) {
          transform: translateY(-25px);
        }
      }
      .client-informations {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 20px;
        .input-container {
          width: 48%;
          @media (max-width: 1024px) {
            width: 95%;
            margin: 0 auto;
          }
          .mdc-text-field {
            width: 100%;
          }
        }
        .city-container {
          width: 48%;
          display: inline-flex;
          @media (max-width: 1024px) {
            width: 95%;
            margin: 0 auto;
          }
          .input-container:last-child {
            margin-left: 4%;
          }
        }
      }
      .isClient-container {
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
        min-height: 109px;

        @media (max-width: 1024px) {
          margin-bottom: 20px;
          min-height: 97px;
          @media (max-width: 1108px) {
            min-height: 127px;
          }
        }


        > div {
          width: 48%;
          @media (max-width: 1024px) {
            width: 95%;
            margin: 0 auto;
          }
        }
        .yesNoBoxes {
          @media (max-width: 1024px){
            position: relative;
            left: -10px;
            margin-bottom: 20px;
          }
        }
        .clientBranch {
          opacity: 0;
          transition: opacity 0.1s ease-in, display 0s ease-out .1s;
          pointer-events: none;
          display: none;

          @media (max-width: 1024px){
            max-height: 0;
          }
          &.show {
            opacity: 1;
            transition: opacity 0.2s ease-in .1s;
            pointer-events: all;
            display: block;

            @media (max-width: 1024px){
              max-height: unset;
            }
          }
          .mdc-theme--error {
            margin-left: 15px;
          }
        }
        .mdc-select {
          width: 100%;
          background-color: #FFFFFF;
          .mdc-floating-label {
            font-size: 11px;

            @media (max-width: 1024px) {
              left: 4px;
            }
          }
          .mdc-select__dropdown-icon {
            @media (max-width: 1024px) {
              right: 0;
              bottom: 8px;
            }
          }
          &--focused .mdc-select__dropdown-icon {
            background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2210px%22%20height%3D%225px%22%20viewBox%3D%227%2010%2010%205%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cpolygon%20id%3D%22Shape%22%20stroke%3D%22none%22%20fill%3D%22%232B5266%22%20fill-rule%3D%22evenodd%22%20opacity%3D%221%22%20points%3D%227%2010%2012%2015%2017%2010%22%3E%3C%2Fpolygon%3E%0A%3C%2Fsvg%3E");
          }
        }
      }
      .contact-container {
        display: flex;
        justify-content: space-between;
        .contact-item {
          width: 48%;
          @media (max-width: 1024px) {
            width: 95%;
            margin: 0 auto;
          }

          &.needs {
            display: flex;
            flex-direction: column;
            min-height: 125px;
            .mdc-text-field {
              height: 45px;
            }
            @media (max-width: 1024px) {
              margin-bottom: 40px;
            }
          }

          &.schedule-call {
            .mdc-typography--subtitle1{
              padding-top: 10px;
              margin-bottom: 7px;
            }
            .day-preference {
              width: 35%;
              margin-bottom: 15px;
              background-color: rgba(0,0,0,0);
            }
            .time-choice {
              @media (max-width: 1024px){
                position: relative;
                left: -10px;
                margin-bottom: 20px;
              }
            }
          }
        }
      }
      @media (max-width: 1024px) {
        .row {
          flex-direction: column;
          flex-wrap: nowrap;
        }
      }
    }
    &.confirmed{

      .btn-back {
      }
    }
  }
  button {
    background-color: transparent;
  }
  .form-btn {
    border: 1px solid var(--mustard);
    font-size: 14px;
    padding: 12px 14px;
    border-radius: 5px;
    width: 200px;
    display: block;
    margin: 60px auto 0;
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.3s;
    &:hover {
      color: #FFFFFF;
      background-color: #E5BE77;
    }
    &.disabled{
      opacity: 0.3;
      cursor: none;
      &:hover {
        color: initial;
        background-color: initial;
      }
    }
  }
  .required-fields_asterisk{
    align-self: flex-start;
    font-size: 9px;
    line-height: 17px;
    margin-top: 40px;
    margin-left: 20px;

    @media (max-width: 1024px) {
      margin-top: 40px;
    }

    @media (max-width: 550px) {
      margin-top: 70px;
    }
  }
  .close-popin {
    position: absolute;
    top: 115px;
    right: 10%;
    width: 40px;
    border: none;
    color: var(--mustard);
    font-size: 50px;
    cursor: pointer;
    z-index: 9;
    @media (max-width: 1024px){
      top: 5px;
      right: 5%;
    }
  }
  .error {
    opacity: 0;
    transition: opacity 0.35s ease-in-out;
  }
}
#appointment {
  position: relative;
  padding: 0 20px;
  min-width: 568px;

  #error-msg {
    position: absolute;
    width: 100%;
    left: 0;
    width: 100%;
    text-align: center;
    color: #b00020;
    margin-top: 20px;
    font-family: Roboto, sans-serif;
    font-size: 14px;

    @media (max-width: 550px) {
      padding: 0 20px;
    }

    &.hide {
      display: none;
    }
  }

  @media (max-width : 567px) {
    min-width: unset;
  }
}