:root {
  --lightBlue: #C9DAE5;
  --darkerBlue: #2B5266;
  --lightGrey: #9D9D9C;
  --sliderItemsVerticalMargins: 67px;
}

.accompagnement {

  .intro-visual {
    z-index: 3;

    .mask {
      @media(min-width: 1025px) {
        position: absolute;
        transition: all .3s ease-out;
        overflow: hidden;

        &.corner {
          left: 50%;
          bottom: 0;
          width: 50vw;
          height: calc(100vh - 273px);
          margin: 0;

          @media (max-height: 770px) and (min-width: 1025px) {
            height: calc(100vh - 200px);
          }

          .image-container {
            transition: all 0s linear .3s;
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            height: calc(100vh - 273px);

            @media (max-height: 770px) and (min-width: 1025px) {
              height: calc(100vh - 200px);
            }

            img {
              object-position: top;
              transition: object-position 0s linear .3s, opacity .3s ease-out .2s;
              opacity: 1;
            }
          }

          &.out {
            height: 0;
            bottom: auto;
            top: 273px;
            transition: height .3s ease-out .2s;

            @media (max-height: 770px) and (min-width: 1025px) {
              top: 200px;
            }

            .image-container {
              img {
                opacity: 0;
              }
            }
          }
        }

        &.fadeOut {
          opacity: 0;

          .marie-louise {
            display: none;
          }
        }
        .image-container {
          img {
            object-position: 50% 95%; 
          }
        }
      }
      @media(min-width: 1280px) {
        .image-container {
          img {
            object-position: 50% 75%; 
          }
        }
      }
      @media(max-width: 1024px) {
        .image-container {
          img {
            object-position: 50% 75%;
          }
        }
      }
    }
  }

  .private-advisor {
    z-index: 2;
    background-color: transparent;
    .text-container {
      transition: transform .4s ease-out, opacity .4s ease-out .4s;
      .section-title {
        width: 340px;
        @media (max-width: 767px){
          text-align: center;
          margin: 0 auto 14px auto;
        }
        @media (max-width: 480px){
          width: 220px;
          text-align: center;
          margin: 0 auto 14px auto;
        }
      }
    }

    &.out {
      background-color: rgba(255, 255, 255, 0);
      transition: background-color 1s ease-out .5s;


      header {
        p, h2 {
          transform: translate3d(0, -30px, 0);
          opacity: 0;
        }

        p {
          transition-delay: .15s;
        }
      }

      .text-container {
        transition: transform .2s ease-out, opacity .2s ease-out;
        transform: translate3d(0, -30px, 0)!important;
        opacity: 0;
        transition-delay: .3s;
      }
    }
  }

  .private-advisor:not(.intro-visual) {

    header {
      margin-bottom: 60px;
      @media (max-width: 767px){
        margin-bottom: 30px;
      }
    }

    /* .container {
      @media(min-width: 1025px) {
        width: 100%;
        left: 0;
        bottom: 0;
        overflow: hidden;

        .img-container {
          transition: opacity .2s ease-out;

          &.fadeIn {
            opacity: 1;
            transition-delay: .2s;
          }
        }
      }

      @media (max-height: 770px) and (min-width: 1025px) {
        height: calc(100vh - 200px);
      }
    } */
  }

  .finance-engineering {
    z-index: 1;
    padding: 0;

    @media(min-width: 1024px) {
      header {
        h1, p {
          /* opacity: 0; */
          transition: transform .2s ease-out, opacity .2s ease-out;
          transform: translate3d(0, 30px, 0);
        }
      }

      ol {
        li {
          /* opacity: 0; */
          transition: all .2s ease-out;
          transform: translate3d(0, 30px, 0);
        }
      }

      .sub-2 {
        /* opacity: 0; */
        /* transform: translate3d(0, 60px, 0); */
        /* transition: all .4s ease-out; */
      }
    }

    @media (max-width: 1023px) and (min-width: 768px){
      padding: 0 3.125%;
    }

    &.in {
      header {
        h1, p {
          opacity: 1;
          transform: translate3d(0, 0, 0);
        }

        h1 { transition-delay: .5s; }
        p { transition-delay: .6s; }
      }

      .sub-2 {
        @media(min-width: 1025px) {
          opacity: 1;
          transform: translate3d(0, 0, 0);
          transition-delay: .5s;
        }
      }

      ol {
        li {
          transform: translate3d(0, 0, 0);
          opacity: 1;

          &:nth-child(1) { transition-delay: .8s; }
          &:nth-child(2) { transition-delay: 1s; }
          &:nth-child(3) { transition-delay: 1.2s; }
        }
      }
    }

    header {
      margin-bottom: 70px;
      @media (max-width: 768px) {
        margin-bottom: 30px;
      }
    }

    ol {
      list-style: none;
      counter-reset: my-awesome-counter;
      display: block;
      position: relative;
      width: 100%;
      margin: 0 auto 85px;
      width: 90%;

      @media(max-width: 1240px) {
        width: 100%;
      }
      @media (max-width: 1024px) {
        display: flex;
        padding: 0 15px;
      }

      li {
        position: relative;
        display: inline-block;
        width: 33%;
        counter-increment: my-awesome-counter;
        font-family: 'Libre Baskerville', serif;
        color: #000000;
        vertical-align: top;
        font-size: 13px;
        letter-spacing: 1px;
        line-height: 30px;
        box-sizing: border-box;

        @media(max-width: 1023px) {
          font-size: 12px;
          line-height: 26px;
        }

        @media (max-width: 767px) {
          font-size: 10px;
          line-height: 24px;
          padding: 40px 0 0 0!important;
        }

        &:nth-child(1) {
          padding: 60px 3.8% 0 7%;
          &::before { left: 15%; }

          @media(max-width: 1100px) {
            padding: 60px 0 0 3%;

            &::before { left: 0; }
          }
          @media (max-width: 767px){
            padding-left: 0;
            &::before {
              /* top: -20%; */
              left: 0;
            }
          }
        }

        &:nth-child(2) {
          padding: 60px 0 0 11.3%;
          &::before { left: 25%; }

          @media(max-width: 1100px) {
            padding: 60px 0 0 9%;

            &::before { left: 15%; }
          }
        }

        &:nth-child(3) {
          padding: 60px 0 0 14%;
          &::before { left: 34%; }

          @media(max-width: 1100px) {
            padding: 60px 0 0 11%;

            &::before { left: 20%; }
          }
        }

        &::before {
          content: counter(my-awesome-counter) "";
          position: absolute;
          z-index: -1;
          top: 0;
          left: 35px;
          font-family: 'Libre Baskerville';
          line-height: 1;
          letter-spacing: 1px;
          color: #C9DAE5;
          font-size: 160px;
          opacity: 0.5;

          @media(max-width: 1023px) {
            font-size: 140px;
          }

          @media (max-width: 767px) {
            font-size: 120px;
          }
        }

        strong {
          font-weight: 600;
        }
      }
      @media (max-width: 767px) {
        flex-direction: column;
        margin: 0 auto 30px;
        li {
          width: 90%;
          margin: 0 auto 15% 10%;
          font-size: 14px;
          line-height: 1.85;
          padding-left: 0 !important;
          &::before {
            top: -10%;
            left: -13% !important;
            font-size: 140px;
          }
        }
      }
    }

    .text-title {
      @media (max-width: 767px){
        font-size: 14px;
        line-height: 1.333;
      }
    }

    .slides {
      width: 100%;
      position: relative;

      .container {
        position: relative;
        height: 100%;
        margin-bottom: 35px;
        @media (max-width: 767px) {
          height: auto;
        }

        .slides-list {
          height: 100%;

          @media (max-width: 1024px){
            height: 48vh;
          }
          @media (max-width: 1023px){
            height: 50vh;
          }

          @media (max-width: 768px) {
            height: 50vh;
          }

          @media (max-width: 767px) and (orientation: landscape) {
            height: 80vh;
          }

          @media (max-width: 480px) {
            height: 131.25vw;
          }

          .slide-item {
            display: none;
            height: 100%;

            @media(min-width: 1025px) {
              min-height: 550px;
              height: 100px;
            }

            @media (min-width: 1025px) and (max-height: 770px) {
              min-height: 440px;
            }

            &.active {
              display: flex;

              @media (max-width: 1023px) {
                flex-direction: column;
                align-items: center;
                background: linear-gradient(to bottom, #C9DAE5 50%,#FFF 50%, #FFF 100%);
                padding-top: 3vh;
                padding-bottom: 3vh;
              }
              @media (max-width: 480px) {
                background: linear-gradient(to bottom, #C9DAE5 43.75%,#FFF 43.75%, #FFF 100%);
              }
            }

            .slide-title, .slide-text {
              position: relative;
              display: flex;
              justify-content: left;
              align-items: center;
              padding-top: 140px;
              padding-bottom: 140px;

              @media (min-width: 1025px) and (max-height: 830px) {
                padding-top: 100px;
                padding-bottom: 100px;
              }

              @media screen and (max-width: 1150px) {
                padding-top: 120px;
                padding-bottom: 120px;
              }

              @media (max-width: 1023px) {
                flex-basis: 50%;
                width: 85%;
                padding: 0 !important;
                border: 6px solid #7693A3;
              }
            }

            .slide-title {
              flex-basis: 50%;
              padding-right: 65px;
              padding-left: 176px;

              @media (max-width: 1400px) {
                padding-left: 150px;
              }

              @media (max-width: 1023px) {
                border-color: #FFFFFF;
                border-bottom: none;
                min-height: 17vh;
              }
              @media (max-width: 795px) {
                min-height: 22vh;
              }
              @media (max-width: 767px) {
                flex-basis: 50%;
                min-height: 1vh;
              }
              @media (max-width: 480px) {
                height: 43%;
                height: 56.25vw;
                flex-basis: unset;
                flex-grow: 0;
              }
              h2 {
                font-weight: 400;
                font-size: 26px;
                line-height: 34px;

                @media (max-width: 1240px) {
                  font-size: 23px;
                  line-height: 31px;
                  margin: 0 auto;
                }
                @media (max-width: 1023px) {
                  text-align: center;
                  font-size: 24px;
                  line-height: 28px;
                }
                @media (max-width: 767px) {
                  font-size: 16px;
                  line-height: 24px;
                }
              }
            }

            .slide-text {
              flex-basis: 50%;
              padding-left: 65px;
              padding-right: 160px;

              @media screen and (max-width: 1150px) {
                padding-right: 100px;
                padding-left: 40px;
              }

              @media (min-width: 1025px) and (max-height: 770px) {
                padding: 90px 120px 90px 45px;
              }

              @media (max-width: 1023px) {
                border-top: none;
              }
              @media (max-width: 767px) {
                flex-basis: 50%;
              }
              @media (max-width: 480px) {
                height: 67%;
                height: 75vw;
                flex-basis: unset;
                flex-grow: 0;
              }

              p {
                color: #000;

                @media(max-width: 1023px) {
                  padding: 0 25px;
                  font-size: 16px;
                  line-height: 28px;
                }
                @media (max-width: 767px) {
                  font-size: 12px;
                  line-height: 2;
                  margin-bottom: 0;
                }
                @media (max-width: 360px) {
                  /* font-size: 12px; */
                }
                @media (max-width: 320px) {
                  font-size: 10px;
                }
              }
            }
          }
        }

        .slider-arrows {
          position: absolute;
          right: -10px;
          top: 0;
          display: flex;
          width: 67px;
          height: 100%;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          @media(max-width: 1240px) {
            width: 50px;
          }

          @media (max-width: 1023px) {
            right: 0;
            position: relative;
            flex-direction: row;
            justify-content: space-between;
            width: 85%;
            height: auto;
            margin-left: auto;
            margin-right: auto;
            margin-top: 10px;
          }
          @media (max-width: 767px) {
            margin-top: 0;
          }

          .slider-arrow {
            width: 100%;
            margin: 8px 0;
            text-align: center;
            cursor: pointer;
            opacity: 1;
            transition: opacity .2s ease-out;

            &.hide {
              opacity: 0;
              pointer-events: none;
            }

            p {
              text-align: center;
              font-family: 'Montserrat', sans-serif;
              color: var(--lightGrey);
              font-size: 11px;
              line-height: 1;
              text-transform: uppercase;
              letter-spacing: 0;
            }

            img {
              width: 15px;
              height: 28px;
              display: inline-block;
              margin-bottom: 11px;
            }

            @media (max-width: 1023px) {
              display: inline-block;
              display: flex;
              align-items: center;
              text-align: center;
              width: 110px;
              &[data-arrow="next"]{
                justify-content: flex-end;
                p {
                  margin-left: 0;
                  margin-right: 20px;
                  order: -1;
                }
              }
              p {
                font-size: 14px;
                margin-left: 20px;
              }
              img {
                width: 20px;
                margin-bottom: 0px;
              }
            }
          }
        }

        .background {
          position: absolute;
          overflow: hidden;
          z-index: -5;
          top: 0;
          width: 50%;
          height: 100%;

          &_left {
            left: 0;
            background-color: var(--lightBlue);
            transition: width 0.5s;
          }

          &_right {
            right: 0;
          }
          @media (max-width: 1023px){
            display: none;
          }

          .marie-louise {
            margin: var(--sliderItemsVerticalMargins) 0;
            border: 10px solid;
            height: calc(100% - 134px);

            @media(max-width: 1100px) {
              border: 6px solid;
              height: calc(100% - 100px);
              margin: 50px 0;
            }

            &_right {
              margin-right: var(--sliderItemsVerticalMargins);
              border-left: none;

              @media(max-width: 1100px) {
                margin-right: 50px;
              }
            }

            &_left {
              margin-left: var(--sliderItemsVerticalMargins);
              border-right: none;
              border-color: #FFF;

              @media(max-width: 1100px) {
                margin-left: 50px;
              }
            }
          }
        }
      }
    }

    .tabs-list {
      display: flex;
      width: 75%;
      height: 57px;
      justify-content: space-between;
      align-items: flex-end;
      margin: 0 auto;

      .tab-item {
        position: relative;
        flex-basis: 30.43%;
        height: 100%;

        &.active {
          a {
            color: var(--darkerBlue);
            font-weight: 600;
            border-bottom-color: var(--darkerBlue);
          }
        }

        &:hover {
          a {
            color: var(--darkerBlue);
            border-bottom-color: var(--darkerBlue);
          }
        }

        a {
          width: 100%;
          display: block;
          position: absolute;
          bottom: 0;
          padding-bottom: 18px;
          left: 0;
          font-size: 15px;
          line-height: 19px;
          text-transform: uppercase;
          color: var(--lightBlue);
          border-bottom: 2px solid var(--lightBlue);
          transition: all 0.15s ease-out;

          @media (min-width: 1700px) {
            font-size: 17px;
          }
        }
      }
      @media (max-width: 1024px) {
        width: 95%;
      }
      @media (max-width: 768px) {
        display: none;
      }
    }
  }

  .patrimonial-engineering {
    @media (max-width: 1023px) and (min-width: 768px){
      padding: 0 3.125%;
    }

    .two-cols {
      align-items: center;
      .text-paragraph {
        margin-bottom: 0;
      }
      @media (max-width: 1023px) {
        flex-direction: column;
        min-height: 430px;
        @supports (position: sticky){
          min-height: unset;
        }
        .text-paragraph {
          text-align: center;
        }
      }
      @media (max-width: 767px) {
        .text-paragraph {
          text-align: left;
        }
      }
    }

    @media (min-width: 1024px) {
      min-height: 650px;
    }

    .section-title {
      margin-bottom: 90px;
      @media (max-width: 1023px) {
        margin-bottom: 15px;
      }
      @media (max-width: 767px){
        margin-bottom: 20px;
      }
    }

    .text-container {
      padding-left: 0;
      padding-top: 0;
      @media (max-width: 1023px){
        padding-right: 0;
        margin: 0 auto 40px;
        width: 85.75vw;
        flex-basis: unset;
      }
    }

    .quote-container {
      flex-basis: 50%;
      padding:0;
      position: relative;
      left: .125%;
      @media (max-width: 1024px){
        flex-basis: 50%;
      }
      @media (max-width: 1023px){
        margin: 0 auto 40px;
        width: 85.75vw;
        padding-top: 15px;
        flex-basis: unset;
      }

      blockquote {
        position: relative;
        font-family: 'Libre Baskerville';
        font-style: italic;
        font-size: 22px;
        line-height: 42px;
        color: var(--darkerBlue);
        letter-spacing: 0;
        text-align: center;
        padding: 12% 18%;
        border: 7px solid #C9DAE5;

        .first-char,
        .last-char {
          display: inline-block;
          position: relative;

          &::before {
            display: block;
            position: absolute;
            font-family: 'Libre Baskerville';
            font-size: 80px;
            line-height: 42px;
            @media (max-width: 1024px){

            }
            @media (max-width: 767px){
              font-size: 62px;
            }
          }
        }

        .first-char {
          &::before {
            content: '“';
            top: -2px;
            left: -60px;
            @media (max-width: 1023px){
              left: -145px;
              top: 10px;
            }
            @media (max-width: 767px){
              top: -5px;
              left: -40px;
            }
          }
        }

        .last-char {
          &::before {
            content: '”';
            bottom: -30px;
            right: -45px;
            @media (max-width: 1023px){
              right: -105px;
              bottom: -40px;
            }
            @media (max-width: 767px){
              bottom: -30px;
              right: -30px;
            }
          }
        }

        @media (max-width: 1280px){
          padding: 12% 16%;

        }
        @media (max-width: 1180px){
          padding: 12% 12%;

        }
        @media (max-width: 1023px){
          padding: 5% 20%;
          /* font-size: 20px; */
          line-height: 38px;
          &::before {
            top: 0;
            left: 12%;
          }
          &::after {
            bottom: -14%;
          }
        }
        @media (max-width: 767px) {
          padding: 10% 10%;
          font-size: 16px;
          line-height: 24px;
          &::before {
            top: 0;
            left: 12%;
          }
          &::after {
            bottom: -14%;
          }
        }
      }
    }
  }
}

.accompagnement{
  .ie{
    .private-advisor{
      @media (min-width: 769px){
        padding-top: 128px;
      }
      .text-container{
        @media (min-width: 769px){
          position: relative;
          top: 22px;
        }
      }
    }
  }
}