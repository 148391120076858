:root {
  --mainColor: #2B5266;
}

header {
  position: relative;
  p {
    width: 60%;
    margin: 0 auto;
    font-family: 'Libre Baskerville', serif;
    font-size: 16px;
    color: #000000;
    letter-spacing: 1px;
    text-align: center;
    line-height: 35px;

    @media (min-width: 1025px) and (max-height: 770px) {
      font-size: 15px;
      line-height: 30px;
    }

    @media (max-width: 1100px) {
      width: 84%;
    }

    @media (max-width: 768px) {
      font-size: 14px;
      line-height: 23px;
    }
  }
}

.header-nav {
  position: relative;
  z-index: 4;
  width: 100%;
  height: 160px;
  background: #fff;
  transform: translate3d(0, 0, 0);
  transition: top .3s ease-out;
  @media (min-width: 1025px) {
    &:not(.has-subnav){
      &:after{
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background: #F5F5F7;
        z-index: 2;
      }
    }
  }
  @media (max-width: 1024px) {
    &:after{
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background: #F5F5F7;
      z-index: 2;
    }
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    position: fixed;
    height: 97px;
    top: 0;
    left: 0;
  }
  @media (max-width: 767px) {
    position: fixed;
    top: 0;
    left: 0;
    height: 85px;
  }
  @media (max-width: 480px) {
    height: 65px;
  }
  &.has-subnav{
    @media (min-width: 1025px) {
      height: 200px;
    }
    .content{
      @media (min-width: 1025px) {
        height: 100%;
        padding-bottom: 40px;
        position: relative;
      }
    }
  }
  .content {
    .link-home {
      width: 209px;
      height: 74px;
    }
  }

  &.small {
    position: fixed;
    top: -200px;
    left: 0;
    width: 100%;
    height: auto;
    visibility: hidden;
    @media (min-width: 1025px){
      &:after{
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background: #F5F5F7;
        z-index: 2;
      }
    }
    &.show{
      top: 0;
      visibility: visible;
    }
    .content {
      .link-home {
        left: 100px;
        top: 18px;
        transition: none;
        width: 150px;
        height: auto;
        @media (max-width: 1440px) {
          left: 0;
        }
        @media (max-width: 1024px) {
          width: 272px;
          height: 55px;
          top: auto;
          left: auto;
        }
        @media (max-width: 767px) {
          width: 240px;
        }
        @media (max-width: 480px) {
          width: 206px;
          height: auto;
        }
        img{
          &.logo-small{
            display: block;
            @media (max-width: 1024px) {
              display: none;
            }
          }
          &.logo-big{
            display: none;
            @media (max-width: 1024px) {
              display: block;
            }
          }
        }
      }

      .nav-container {
        padding-top: 30px;
        height: 86px;
        @media screen and (max-width: 1024px){
          padding: 19px 0;
          height: 97px;
        }
        @media screen and (max-width: 767px){
          height: 85px;
          padding: 15px 0;
        }
        @media screen and (max-width: 480px){
          height: 65px;
          padding: 13px 0;
        }
        &:after {
          height: 4px;
        }
      }
    }
  }

  .nav-container {
    background-color: #FFFFFF;
    text-align: center;
  }

  .content {
    transition: transform .2s ease-out;
    .nav-container {
      position: relative;
      z-index: 1;
      text-align: center;
      padding-top: 0px;
      transform: translate3d(0, 0, 0);
      padding-top: 115px;

      @media (max-width: 1024px) {
        height: auto;
        padding: 19px 0;
        position: relative;
        width: 100%;
        top: 0;
        transform: none;
        transition: none;
      }
      @media (max-width: 767px) {
        height: 85px;
        padding: 15px 0;
      }
      @media (max-width: 480px){
        padding: 13px 0;
        height: 65px;
      }
    }

    .link-home {
      display: inline-block;
      outline: none;
      width: 272px;
      height: 55px;
      transform: translate3d(0, 0, 0);
      position: relative;
      z-index: 1;
      @media (min-width: 1025px) {
        position: absolute;
        width: 334px;
        height: 69px;
        top: 30px;
        left: calc(50% - 167px);
      }
      @media (max-width: 767px) {
        width: 240px;
      }
      @media (max-width: 480px) {
        width: 206px;
        height: auto;
      }
      img {
        display: block;
        width: 100%;
        @media (max-width: 640px){
          width: 100%;
        }
        &.logo-big{
          display: block;
        }
        &.logo-small{
          display: none;
        }
      }
    }
  }
}

.home{
  .header-nav {
    @media (min-width: 1025px) {
      &:not(.has-subnav){
        &:after{
          background: #fff;
        }
      }
    }
  }
}

.solutions {
  .header-nav {
    .content {
      .title {
        height: 220px;
      }
    }
  }
}

.accompagnement {
  .header-nav {
    .content {
      .title {
        height: 185px;
      }
    }
  }
}