:root {
  --textMarginBottom: 4.775%;
}

.text-paragraph {
  font-family: 'Libre Baskerville', serif;
  font-size: 13px;
  line-height: 30px;
  color: #000000;
  margin-bottom: var(--textMarginBottom);
  letter-spacing: 0.7px;
  @media (max-width: 1023px) {
    font-size: 12px;
    line-height: 26px;
  }
  @media (max-width: 767px) {
    font-size: 10px;
    line-height: 24px;
  }

  sup {
    font-size: 13px;
    line-height: 13px;
    @media screen and (max-width: 768px){
      font-size: 10px;
      line-height: 10px;
    }
    @media screen and (max-width: 480px){
      font-size: 8px;
      line-height: 8px;
    }
  }

  strong {
    font-weight: 600;
  }
}

.section-paragraph {
  width: 78%;
  @media (max-width: 1280px) {
    width: 87%;
  }
}

.reveal {
  & > * {
    /* opacity: 0;
    transform: translate3d(0, 30px, 0);
    transition: opacity .5s ease-out, transform .2s ease-out; */
    @media (max-width: 3024px) {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  &.active {
    & > * {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      &:nth-child(1) { transition-delay: 0s; }
      &:nth-child(2) { transition-delay: .2s; }
      &:nth-child(3) { transition-delay: .4s; }
      &:nth-child(4) { transition-delay: .6s; }
      &:nth-child(5) { transition-delay: .8s; }
      &:nth-child(6) { transition-delay: 1s; }
    }
  }
}

.hide {
  & > * {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: opacity .5s ease-out, transform .2s ease-out;
  }

  &.active {
    & > * {
      opacity: 0;
      transform: translate3d(0, 30px, 0);
      &:nth-child(1) { transition-delay: 0s; }
      &:nth-child(2) { transition-delay: .2s; }
      &:nth-child(3) { transition-delay: .4s; }
      &:nth-child(4) { transition-delay: .6s; }
      &:nth-child(5) { transition-delay: .8s; }
      &:nth-child(6) { transition-delay: 1s; }
    }
  }
}