:root {
  --headingsColor: #285066;
}

h1,h2,h3,h4 {
  color: var(--headingsColor);
  text-transform: uppercase;

}

.page-title {
  margin: 58px auto 30px;
  font-family: 'Montserrat', Arial, sans-serif;
  font-size: 36px;
  font-weight: 300;
  line-height: 48px;
  text-align: center;

  @media (max-width: 1100px) {
    font-size: 32px;
    line-height: 44px;
  }
  @media (max-width: 1023px) {
    font-size: 28px;
    line-height: 40px;
  }
  @media (max-width: 480px) {
    font-size: 22px;
    line-height: 32px;
  }
}

.section-title {
  font-family: 'Libre Baskerville', serif;
  font-style: italic;
  font-weight: 400;
  font-size: 40px;
  line-height: 52px;
  text-transform: none;
  margin-bottom: 14px;
  sup {
    font-size: 0.4em;
  }

  @media (max-width: 1024px) {
    font-size: 36px;
    line-height: 44px;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 28px;
    line-height: 36px;
  }
  @media (max-width: 767px) {
    font-size: 22px;
    line-height: 30px;
  }

  & ~ .separator {
    width: 65px;
    border: 2px solid #285066;
    margin: 40px 0 50px;
    @media(max-width: 1240px){
      margin: 37px 0 45px;
    }
    @media(max-width: 1023px){
      margin-top: 26px;
    }
    @media(max-width: 767px){
      display: none;
      width: 0;
    }
  }
}

.text-title {
  font-size: 18px;
  line-height: 1.2857;
  font-weight: 400;
  margin-bottom: 3.175%;
  margin-bottom: 40px;

  @media (min-width: 1700px) {
    font-size: 20px;
  }

  strong {
    font-weight: 600;
  }

  .solutions .slide-item &:not(.tips) {
    font-size: 32px;
    font-weight: 400;
    line-height: 40px;

    @media(max-width: 1100px) {
      font-size: 27px;
      line-height: 34px;
    }
    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 22px;
    }
  }
}

.text-subtitle {
  font-family: 'Libre Baskerville', serif;
  text-transform: none;
  font-size: 15px;
  line-height: 33px;
  color: #000000;
  font-weight: 600;
  letter-spacing: 1px;

  @media (max-width: 1024px) {
    font-size: 13px;
    line-height: 31px;
  }
  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 26px;
  }

  strong {
    font-weight: 600;
  }
}