:root {
  --mainColor: #2B5266;
  --whiteBgc: #FFFFFF;
  --lightGrey: #9D9D9C;
  --contactOrange: #E5BE77;
}

nav {
  display: block;
  align-items: baseline;
  &.subNavDesktop{
    position: absolute;
    bottom: -40px;
    height: 40px;
    width: 100%!important;
    left: 0;
    display: none;
    @media (min-width: 1025px) {
      display: block;
    }
    &.no-sub{
      border-top: 2px solid #F5F5F7;
      .nav-list.nav-sub.activeSubNav{
        display: none!important;
      }
    }
    .nav-list {
      &.nav-sub {
        display: none;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        text-align: center;
        background: #F5F5F7;
        padding: 11px 0;
        &.activeSubNav{
          display: block!important;
          z-index: 2;
        }
        &.show{
          display: block;
          position: absolute;
          width: 100%;
          left: 0;
          bottom: 0;
          text-align: center;
          z-index: 3;
        }
        li {
          font-size: 13px;
          letter-spacing: 0.6px;
          text-align: center;
          color: var(--mainColor);
          font-weight: 500;
          line-height: 1.23;
          display: inline-block;
          padding: 0 20px;

          @media(min-width: 1700px) {
            font-size: 15px;
          }
          a{
            color: #9D9D9C;
            transition: all 0.3s ease-in;
             &:not(.activeLink){
              &:hover{
                font-weight: 600;
                transition: all 0.3s ease-in;
                border: none;
                color: var(--mainColor);
              }
            }
            &.activeLink{
            transition: all 0.3s ease-out;
              color: var(--mainColor);
            }
          }
        }
      }
    }
  }
  @media (max-width: 1024px) {
    opacity: 1;
    position: absolute;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99;
    top: 97px;
    overflow: hidden;
    transition: all 0.35s linear;

    .container {
      transform: translate3d(-100%, 0, 0);
      transition: all 0.15s linear;
      height: 100%;
      position: relative;
      z-index: 2;
    }

    &.onMobile {
      pointer-events: none;

      &:after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background-color: rgba(0,0,0,0.75);
        opacity: 0;
        transition: opacity .1s ease-out;
      }

      .nav-list {
        position: relative;
        z-index: 2;
        height: 100%;
        overflow-y: auto;
        &.nav-sub{
          display: block;
          bottom: auto;
        }
      }
    }

    ul {
      width: 81.25%;
      height: 100%;
      background-color: #FFFFFF;
    }


    li {
      transform: translate3d(-100%,0,0);
      transition: transform 0.75s;
    }

    .nav-mobile_main {
      > li {
        font-size: 15px;
        line-height: 18px;
        text-transform: uppercase;
        /*max-width: 290px;*/
        padding: 16px 10px 16px 20px;

        @media (max-height: 580px) and (orientation: portrait) {
          padding-top: 20px;
          min-height: 80px;
        }
        a{
          padding: 10px 0;
          display: block;
          @media screen and (max-width: 480px){
            font-size: 12px;
          }
        }
        >ul{
          >li{
            a{
              padding: 14px 0;
              text-transform: initial;
              @media screen and (max-width: 1024px){
                font-size: 14px;
              }
              @media screen and (max-width: 480px){
                font-size: 11px;
              }
            }
          }
        }
      }
    }

    .nav-mobile_sub {
      li {
        font-size: 13px;
        margin: 15px 0;
      }
    }

    &.opened {
      opacity: 1;
      transition: all 0.3s linear;
      pointer-events: all;

      &:after {
        opacity: 1;
      }

      .container {
        transform: translate3d(0, 0, 0);
        transition: all 0.3s linear;
      }

      li {
        transform: translate3d(0,0,0);
        transition: transform 0.75s;
      }
    }
  }
  @media (max-width: 481px) {
    top: 84px;
  }
  @media (min-width: 1024px) {
    position: relative;
    width: auto;
    padding: 0 100px;
  }

  .nav-list {
    text-align: right;
    margin-right: 8%;
    transform: translate3d(0, 0, 0);
    text-align: center;
    margin-right: 0;

    &.nav-main {
      height: 40px;
      line-height: 40px;
      margin-bottom: 0;
      >li{
        >a:not(.activeLink){
          &:hover{
            font-weight: 600;
            border: none;
            letter-spacing: -0.1px;
          }
        }
      }

      li:not(:last-of-type) a {
        display: block;
        padding: 0 20px;
        height: 40px;
        line-height: 40px;

        @media(max-width: 1280px) {
          padding: 0 10px;

          &.activeLink {
            padding: 0 8px;
          }
        }
        @media(max-width: 1150px) {
          padding: 0 6px;

          &.activeLink {
            padding: 0 5px;
          }
        }
      }

      li {
        font-size: 13px;
        line-height: 1.286;
        text-transform: uppercase;

        @media(max-width: 1024px) {
          font-size: 14px;
        }

        @media(max-width: 1200px) {
          font-size: 12px;
        }

        @media(min-width: 1700px) {
          font-size: 16px;
        }

        &.last-link { margin-right: 0; }
      }
    }

    &.nav-mobile{
      .nav-sub{
        li{
          display: block;
          width: 100%;
          text-align: left;
          border: none;
          padding-left: 0;
          a{
            color: var(--mainColor);
            padding: 1em 0;
            display: block;
          }
        }
      }
    }
    >li{
      >a {
        &::after {
          @media (min-width: 1024px){
            display: block;
            content: attr(data-title);
            letter-spacing: 0.1px;
            font-weight: 600;
            height: 0;
            overflow: hidden;
            visibility: hidden;
          }
        }
      }
    }
    li {
      color: var(--mainColor);
      font-family: Montserrat;
      list-style: none;
      display: inline-block;

      &.contact-link {
        position: absolute;
        right: 0;
        display: none;
        @media (max-width: 1450px) {
          right: -80px;
        }

        button {
          display: block;
          height: 40px;
          padding: 0 14px;
          margin-left: 100px;
          font-size: 14px;
          font-weight: 100;
          line-height: 1.214;
          text-align: center;
          text-transform: uppercase;
          color: var(--whiteBgc);
          background-color: var(--contactOrange);
          border-radius: 5px;
          border-width: 1px;
          border-color: transparent;
          outline: none;
          cursor: pointer;
          transition: color .1s ease-out, background-color .15s ease-out, border-color .25s ease-out;

          @media(max-width: 1600px) {
            margin-left: 60px;
          }

          @media(max-width: 1280px) {
            margin-left: 40px;
            font-size: 13px;
          }

          @media(max-width: 1150px) {
            font-size: 12px;
            margin-left: 20px;
            padding: 0 7px;
          }

          @media(max-width: 1024px) {
            font-size: 11px;
            margin-left: 10px;
          }

          @media(max-width: 830px) {
            font-size: 10px;
            margin-left: 5px;
          }

          &:hover {
            color: var(--contactOrange);
            background-color: var(--whiteBgc);
            border-color: var(--contactOrange);
          }
        }
      }
    }
    @media (max-width: 1024px) {
      text-align: left;
      margin-right: 0;
      transform: none;
      li {
        display: block;
      }
    }
  }
  .activeLink {
    font-weight: 600;
  }
}


.header-nav {
  &.small{
    nav{
      width: auto;
      padding: 0 100px 0 250px;
      @media screen and (max-width: 1440px){
        padding: 0 40px 0 170px;
      }
      .nav-list{
        &.nav-main{
          display: inline-block;
          margin-left: 50px;
          height: 100%;
          li{
            height: 100%;
            a{
              height: 100%;
            }
          }
        }
      }
    }
  }
  .nav-container {
    @media (max-width: 1024px) {
    }
    nav{
      @media (min-width: 1024px) {
        height: 100%;
      }
      @media (max-width: 1024px) {
        opacity: 1;
        position: absolute;
        left: 0;
        width: 100vw;
        height: calc(100vh - 97px);
        z-index: 99;
        padding-left: 0;
        top: 97px;
        overflow: hidden;
        transition: all .35s linear;
      }
      @media (max-width: 767px) {
        top: 85px;
        height: calc(100vh - 85px);
      }
      @media (max-width: 480px) {
        top: 65px;
        height: calc(100vh - 65px);
      }
    }
    transition: transform 0.35s ease-out;
    @media (max-width: 768px) {
      position: relative;
    }
  }
  .nav-button {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    min-width: 30px;
    line-height: 1;
    border: 0;
    margin: 0;
    padding: 28px 28px;
    outline: none;
    z-index: 9;
    & .line {
      display: block;
      width: 20px;
      height: 2px;
      margin-bottom: 6px;
      background-color: #7693A3;
      @media screen and (min-width: 480px){
        width: 36px;
        margin-bottom: 9px;
        height: 3px;
      }
      &.line-1 {
        opacity: 1;
        transition: opacity .1s ease-out .2s;
      }

      &.line-2 {
        transform-origin: 50% 50%;
        transform: rotate3d(0, 0, 1, 0deg) translate3d(0,0px,0);
        transition: transform .3s ease-out;
      }

      &.line-3 {
        transform-origin: 50% 50%;
        transform: rotate3d(0, 0, 1, 0deg) translate3d(0,0px,0);
        transition: transform .3s ease-out;
        margin-bottom: 0;
      }
    }
    &.active {
      & .line-1 {
        opacity: 0;
        transition: opacity .3s ease-out;
      }

      & .line-2 {
        transform: rotate3d(0, 0, 1, 135deg) translate3d(0,0,0);
        transition: transform .3s ease-out;
        @media screen and (max-width: 480px){
          margin-bottom: 5px;
        }
      }

      & .line-3 {
        transform: rotate3d(0, 0, 1, -135deg) translate3d(8px,8px,0);
        transition: transform .3s ease-out;
        @media (max-width: 480px) {
          transform: rotate3d(0, 0, 1, -135deg) translate3d(6px,5px,0);
        }
      }
    }
    @media (max-width: 1024px) {
      display: initial;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
    @media (max-width: 480px){
      padding: 12px 26px;
    }
  }

}

.header-nav{
  &.has-subnav{
    nav{
      &.subNavDesktop{
        bottom: 0;
      }
    }
  }
  /* nav{
  .nav-list {
      &.nav-sub {
        display: none;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        text-align: center;
        &.show{
          display: block;
          bottom: 0;
        }
      }
    }
  } */
}

@media (max-width: 320px){
  #navMobile .nav-list:not(.nav-sub) li a {
    padding: 0;
  }
  #navMobile .nav-list.nav-mobile .nav-sub li a{
    padding: 5px 0;
  }
  #navMobile .nav-mobile_main > li {
    padding-top: 12px;
    padding-bottom: 12px;
    min-height: auto;
  }
}
@media (max-width: 320px) and (max-height: 480px){
  #navMobile .nav-mobile_main > li {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}