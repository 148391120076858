.cookie-banner {
	position: fixed;
	width: 100%;
	height: 44px;
	left: 0;
	bottom: 0;
	background-color: rgba(228, 236, 242, .9);
	z-index: 3;
	transform: translate3d(0, 0, 0);
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px 40px 5px 5px;
	transition: none;

  @media (max-width: 1024px) {
    height: auto;
   	padding: 20px 20px 20px 10px;
  }
  @media (max-width: 768px){
    padding: 20px 40px 20px 20px;
  }
  

	&.hide {
		display: none;
	}

	p {
		font-family: 'Libre Baskerville', serif;
	  line-height: 44px;
	  color: #000000;
	  letter-spacing: 1px;
	  font-size: 12px;
	  text-align: center;

	  @media (max-width: 1300px) {
			font-size: 10px;
		}

		@media (max-width: 1150px) {
			line-height: 15px;
			text-align: left;
		}

    @media (max-width: 1024px) {
      padding-top: 0;
      line-height: 1em;
    }
    @media (max-width: 769px){
      font-size: 14px;
      line-height: 21px;
    }

	  a {
	  	font-weight: bold;
	  	text-decoration: underline;
	  }
	}

  #cookie-close {
    width: 12px;
    height: 12px;
    position: absolute;
    top: 16px;
    right: 20px;
    background-image: url(../../assets/images/close.png);
    background-size: cover;
    cursor: pointer;

    @media (max-width: 1024px) {
      top: 10px;
      right: 10px;
    }
    @media (max-width: 769px){
      right: 15px;
      width: 20px;
      height: 20px;
    }
  }
}

/* cookies page */
.cookies {
  table {
    width: 80%;
    border-top: 1px solid #2b5266;
    border-bottom: 1px solid #2b5266;
    margin-bottom: 30px;
    th, td {
      padding: 10px 15px;
      &[colspan="3"] {
        width: 25%
      }
      &[colspan="6"] {
        width: 50%
      }
    }

    tbody {
      tr {
        td:first-child {
          font-weight: 600;
        }
      }
      tr:nth-child(2n+1) {
        background-color: #F5F5F7;
        td {

        }
      }
    }
  }
}