:root {
  --mainColor: #2b5266;
  --whiteBgc: #ffffff;
  --lightGrey: #9d9d9c;
  --btnOrange: #e5be77;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  text-align: left;
}

:focus {
  outline: none;
}
/* hide all contact CTAs*/
.contact-button {
  display: none !important;
}
/**/

body {
  color: var(--mainColor);
  font-family: Montserrat;
  margin: 0 auto;
  overflow-x: hidden;
  @media (max-width: 1024px) {
    .formOpen {
      height: 100vh;
      overflow: hidden;
    }
  }
  &.mob-nav-opened {
    overflow: hidden;
  }
}

:any-link {
  text-decoration: none;
}

a,
a:hover,
a:link,
a:visited,
a:active {
  text-decoration: none;
  color: inherit;
}

li {
  list-style: none;
}

a.btn {
  display: inline-block;
  height: 40px;
  padding: 0 25px;
  margin: 0;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  line-height: 40px;
  text-align: center;
  text-transform: uppercase;
  color: var(--whiteBgc);
  background-color: var(--btnOrange);
  border-radius: 5px;
  border-width: 1px;
  border-color: transparent;
  outline: none;
  cursor: pointer;
  border: 1px solid var(--btnOrange);
  transition: color 0.1s ease-out, background-color 0.15s ease-out,
    border-color 0.25s ease-out;

  &:hover {
    color: var(--btnOrange);
    background-color: var(--whiteBgc);
  }
  @media screen and (max-width: 480px) {
    font-weight: 600;
    font-size: 17px;
    padding: 4px 31px;
    height: auto;
  }
  @media screen and (max-width: 480px) {
    font-size: 14px;
  }
}

#root {
  height: auto;
  @media (min-width: 768px) and (max-width: 1024px) {
    padding-top: 97px;
  }
  @media (max-width: 767px) {
    padding-top: 85px;
  }
  @media (max-width: 480px) {
    padding-top: 65px;
  }
  &.marge-top {
    @media (min-width: 1024px) {
      padding-top: 160px;
    }
  }
  .wrapper {
    max-width: 1240px;
    margin: 0 auto;
    @media (max-width: 1280px) {
      width: 93.75vw;
    }
    @media (max-width: 1023px) {
      padding-left: 0;
      padding-right: 0;
      width: 100%;
    }
  }
  .wrapper-large {
    max-width: 1440px;
    margin: 0 auto;
  }
  .header-nav{
    .wrapper-large{
      @media (max-width: 1440px){
        max-width: 1240px;
      }
      @media (max-width: 1280px){
        max-width: 93.75vw;
      }
      @media (max-width: 1024px){
        max-width: none;
      }
    }
  }

  #app {
    transition: opacity 0.3s ease-out;
    opacity: 1;
    position: relative;
    z-index: 1;

    .title {
      position: relative;
      width: 815px;
      margin: 30px auto;
      /* padding: 58px 0 50px; */
      background-color: white;
      @media (min-width: 1024px) {
        margin: 35px auto 30px auto;
      }
      @media (max-width: 768px) {
        width: 100%;
      }
      @media (max-width: 767px) {
        width: 93.75vw;
      }

      .page-title {
        margin: 0 0 30px;

        sup {
          display: inline-block;
          font-size: 15px;
          position: relative;
          top: -5px;
        }
      }

      p {
        margin: 0 auto;
        font-family: "Libre Baskerville", serif;
        font-size: 16px;
        color: #000000;
        letter-spacing: 0.7px;
        text-align: center;
        line-height: 33px;

        @media (min-width: 1025px) and (max-height: 770px) {
          font-size: 15px;
          line-height: 30px;
        }

        @media (max-width: 1100px) {
          width: 84%;
        }

        @media (max-width: 768px) {
          font-size: 14px;
          line-height: 23px;
        }
        @media (max-width: 480px) {
          font-size: 10px;
          line-height: 24px;
        }
      }
    }

    .slider-arrow {
      user-select: none;
    }
  }

  .header-nav {
    .nav-sub,
    .title > * {
      opacity: 1;
    }
  }

  &.hidden {
    #app {
      @media (min-width: 1025px) {
        opacity: 0;
        transition: none;
      }
    }

    .nav-sub,
    .title > * {
      @media (min-width: 1025px) {
        /*opacity: 0;*/
      }
    }
  }
}

.no-events {
  pointer-events: none;
}

.sticky {
  position: sticky;
  top: 0;
  &:before,
  &:after {
    content: "";
    display: table;
  }
}

.text-container {
  .text-title {
    font-size: 26px;
    line-height: 34px;

    @media (max-width: 1024px) {
      font-size: 23px;
      line-height: 31px;
    }

    @media (max-width: 768px) {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 20px;
    }
    @media (max-width: 480px) {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 20px;
    }
  }
  .text-paragraph {
    font-size: 13px;
    line-height: 30px;
    &.bold {
      font-weight: 700;
    }
    @media (max-width: 1023px) {
      font-size: 12px;
      line-height: 26px;
    }

    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 24px;
    }
  }
  @media (max-width: 1024px) {
    transform: none !important;
    opacity: 1 !important;
    height: auto !important;
  }
  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
}

.img-container {
  /* figure {
    figcaption {
      font-size: 30px;

      @media (max-width: 1100px) {
        font-size: 26px;
        line-height: 32px;
      }
      @media (max-width: 1024px) {
        font-size: 16px;
        line-height: 22px;
        padding: 10px;
        transform: translate3d(0%, -10%, 0);
      }
    }
  } */
  position: relative;
  .marie-louise {
    position: absolute;
    border: 4px solid #fff;
    top: 30px;
    bottom: 30px;
    left: 30px;
    right: 30px;
    @media (max-width: 1024px) {
      top: 25px;
      bottom: 25px;
      left: 25px;
      right: 25px;
    }
    @media (max-width: 768px) {
      top: 15px;
      bottom: 15px;
      left: 15px;
      right: 15px;
    }
  }
  &.sticky {
    position: sticky;
    top: 166px;
    height: calc(100vh - 206px);
    overflow: hidden;
    min-height: 394px;
    max-height: 693px;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      margin-top: 0 !important;
      position: relative !important;
      @media (max-width: 767px) {
        display: block;
      }
    }
    /* @media (max-height: 800px){
      top: 22%;
    }

    @media (max-height: 700px){
      top: 25%;
    }
    @media (max-height: 600px){
      top: 27%;
    } */

    @media (max-width: 1240px) {
      min-height: 394px;
    }
    @media (max-width: 1023px) {
      min-height: unset;
      max-height: 385px;
    }
    @media (max-width: 767px) {
      min-height: unset;
      max-height: unset;
      height: auto;
      position: relative;
      top: auto;
    }
  }
  @media (max-width: 1024px) and (min-width: 768px) {
    &.sticky {
      top: 137px !important; /*header + 20px */
      /* height: 537px; */
    }
  }
}

.mentions {
  padding: 60px 0 60px;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  color: #9d9d9c;
  letter-spacing: 0;
  line-height: 17px;
  @media (max-width: 1023px){
    padding-left: 25px;
    padding-right: 25px;
  }
  @media (max-width: 767px){
    font-size: 10px;
    line-height: 17px;
  }  
  @media (max-width: 1460px) {  
    .wrapper-large & {
      padding-left: 25px;
      padding-right: 25px;
    }  
  }
}

html:not(.home) section:not(.intro-visual) {
  padding-top: 150px;
  @media (max-width: 1024px) {
    padding-top: 100px;
  }
  @media (max-width: 767px) {
    padding-top: 70px;
  }
}

section.go-bg-red{
  background: #a13206!important;
  .content{
    background: #a13206!important;
  }
}

.onMobile {
  display: none;
}

.swipe-container {
  /* z-index: 1;
  width: 100%;
  height: 100vh; */
}

@media (max-width: 1024px) {
  .onMobile {
    display: initial;
    &.contactMobile {
      /* mobile form button*/
      position: fixed;
      bottom: 10px;
      right: 10px;
      z-index: 2;
    }
  }
  .onDesktop {
    display: none !important;
  }
  .swipe-container {
    /* height: auto; */
  }
  #closeMobNav {
    display: block;
    width: 18.75%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
  }
}
@media (min-width: 767px) {
  .img-container.onDesktop {
    display: initial;
  }
  .img-container.onMobile {
    display: none;
  }
}

.mentions-legales {
  .header-nav {
    position: relative;
  }
  .text-paragraph {
    margin-bottom: 20px;
    line-height: 25px;

    a {
      text-decoration: underline;
    }
  }
}
