.legals {
  .header-nav {
    position: relative;
  }

  section {
    padding: 50px;

    @media (max-width: 600px) {
      padding: 40px 20px;
    }

    p {
      margin-bottom: 35px;
    }
  }
}

.mentions-legales, .cookies {
  .legals {
    section {
      @media (min-width: 601px) {
        padding: 50px!important;
      }
    }
  }
}
