:root {
  --mainColor: #2b5266;
  --headingsColor: #285066;
  --whiteBgc: #ffffff;
  --textPad: 125px;
  --textMarginBottom: 40px;
}

.two-cols {
  display: flex;
  /* overflow: hidden; */
  .text-container,
  .img-container {
    width: 50%;
  }
  img {
    width: 100%;
  }

  &_img-left {
    .text-container {
      order: 1;
      padding-left: 125px;
      @media (max-width: 1240px) {
        padding-left: 70px;
      }
      @media (max-width: 1023px) {
        padding-left: 50px;
        padding-right: 16px;
      }
      @media (max-width: 767px) {
        padding-left: 24px;
        padding-right: 0;
        .img-container {
          margin-right: auto;
          margin-left: 0;
        }
      }
    }
    @media (max-width: 767px) {
      .img-container {
        margin-right: auto;
        margin-left: 0;
      }
    }
  }
  &_img-right {
    .text-container {
      order: -1;
      padding-right: 125px;
      @media (max-width: 1240px) {
        padding-right: 70px;
      }
      @media (max-width: 1023px) {
        padding-right: 50px;
        padding-left: 16px;
      }
      @media (max-width: 767px) {
        margin-left: auto;
        padding-right: 8.125vw;
        padding-left: 0;
      }
    }
    @media (max-width: 767px) {
      .img-container {
        margin-right: 0;
        margin-left: auto;
      }
    }
  }
  .text {
    margin-top: 3.225%;
    height: 100%;
    overflow: auto;

    &-paragraph {
      font-family: "Libre Baskerville", serif;
      color: #000000;
      letter-spacing: 0.7px;
      margin-bottom: var(--textMarginBottom);

      /* @media (min-width: 1025px) and (max-height: 770px) {
        line-height: 30px;
      }

      @media (max-width: 1100px) {
        line-height: 30px;
      }

      @media (max-width: 768px) {
        line-height: 20px;
      } */
      @media (max-width: 767px) {
        margin-bottom: 30px;
      }
      @media (max-width: 480px) {
        margin-bottom: 12px;
      }
    }
  }

  @media (max-width: 767px) {
    flex-direction: column;
    .text-container {
      order: -1;
    }
    .text-container,
    .img-container {
      width: 93.75vw;
    }
  }
}

figure {
  position: relative;

  figcaption {
    position: absolute;
    right: 0;
    top: 0;
    padding: 0 0 25px 30px;
    background-color: var(--whiteBgc);
    color: var(--mainColor);
    font-family: Baskerville;
    font-style: italic;
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 42px;
    text-align: left;

    span {
      position: relative;
      right: -20px;
      top: -5px;
    }

    @media (max-width: 767px) {
      transform: translate3d(1%, -15%, 0);
      width: 75%;
      padding: 3.825% 3.485%;
      font-size: 14px;
      line-height: 1.66;
    }
  }
}
