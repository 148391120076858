.header-nav {
  padding-bottom: 0;
  nav {
    /*padding-bottom: 1.375%;*/
  }
}

.intro-visual {
  width: 100%;
  height: 540px;
  position: relative;
  top: 0;
  overflow: hidden;

  @media (max-width: 1024px) {
    height: 420px;
  }
  @media (max-width: 768px) {
    height: 335px;
  }
  @media (max-width: 480px) {
    height: 230px;
  }
  

  /* &.fullViewHeight {
    height: auto;
  } */
  &.show-borders {
    .mask {
      .image-container {
        .marie-louise {
          opacity: 1;
        }
      }
    }
  }
  header {
    margin: 58px auto 50px;

    .page-title {
      margin: 0 0 30px;
    }
  }

  .mask {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    bottom: 0;
    margin: auto;
    overflow: hidden;
    @media (min-width: 1025px) {
      transition: all 0.3s ease-out;
    }
    @media (max-width: 1024px) {
      width: 100%;
      height: 100%;
      left: 0;
      bottom: 0;
      margin: 0;
    }

    .image-container {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      overflow: hidden;

      @media (min-width: 480px) {
        transition: transform 0.4s ease-out;
        picture {
          display: block;
          overflow: hidden;
          height: 100%;
          img {
            object-position: center;
            transition: object-position 0s linear 0s;
            height: 115%;
          }
        }

        &.dezoom {
          transform: scale(1);
        }
      }
      @media (max-width: 1024px) {
        height: 100%;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: flex;
        object-position: center;
        display: block;
      }

      .marie-louise {
        position: absolute;
        border: 4px solid #fff;
        top: 30px;
        bottom: 30px;
        left: 30px;
        right: 30px;
        opacity: 0;
        transition: opacity 0.8s ease-out;

        @media (max-width: 1024px) {
          /* opacity: 0; */
          top: 25px;
          bottom: 25px;
          left: 25px;
          right: 25px;
        }
        @media (max-width: 1024px) {
          border-width: 4px;
        }
        @media (max-width: 767px) {
          border-width: 3px;
        }
      }
      @media (max-width: 767px) {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media (min-width: 480px) {
  .intro-visual,
  .intro-home {
    .mask .image-container {
      transition: transform 0.4s ease-out;
      picture {
        display: block;
        overflow: hidden;
        height: 100%;
        img {
          object-position: center;
          transition: object-position 0s linear 0s;
          height: 115%;
        }
      }
    }
  }
}
