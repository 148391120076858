:root{
  --navWidth: 58.472%;
}

footer {
  width: 100%;
  background-color: #fff;
  border-top: 2px solid #F2F2F2;
  position: relative;
  top: 0;
  padding: 31px;

  .logo {
    margin: 31px 0;
    width: 170px;
  }

  .nav {
    text-align: center;

    .footer-nav-list {
      text-align: center;
      margin-right: 0;
      font-size: 0;

      li {
        padding: 0;
        margin: 0;
        height: 12px;
        color: #2b5266;
        font-family: Montserrat;
        list-style: none;
        display: inline-block;

        @media (min-width: 1025px) {
          margin-top: 0;
        }

        @media (max-width: 1024px){
          line-height: 30px;
          margin-bottom: 25px;
          border: none !important;
          &:last-child{
            margin-bottom: 0;
          }
        }
        a {
          font-size: 11px;
          letter-spacing: 0;
          padding: 0 14px;
          height: 100%;
          display: block;
          line-height: 14px;
          text-transform: uppercase;

          @media (min-width: 1700px) {
            font-size: 13px;
          }

          @media(max-width: 1024px) {
            font-size: 10px;
            padding: 0 8px;
          }
        }
      }

      @media (max-width: 1024px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }

}