:root {
  --mainColor: #2B5266;
  --whiteBgc: #FFFFFF;
  --lightGrey: #9D9D9C;
  --lightBlueBgc: #C9DAE5;
  --transparent: rgba(0,0,0,0);
}

@import '../layouts/two-cols.css';

.entrepreneurs {
  .intro-visual {
    .mask {
      .image-container {
        img {
          object-position: 50% 120%;
        }
      }
    }
  }
  .img-container.sticky {
    top: 126px;
    height: calc(100vh - 166px);
    @media (max-width: 767px){
      min-height: unset;
      max-height: unset;
      height: auto;
      position: relative;
      top: auto;
    }
  }
  .near-you{
    .img-container.sticky {
      img{
        @media (max-height: 700px) {
          object-position: 50% 70%;
        }
        @media (max-height: 650px) {
          object-position: 50% 80%;
        }
        @media (max-height: 610px) {
          object-position: 50% 90%;
        }
      }
    }
  }
}

.entrepreneurs{
  .ie{
    .assist-your-project{
      @media (min-width: 769px){
        padding-top: 32px;
        padding-bottom: 118px;
      }
      .text-container{
        @media (min-width: 769px){
          position: relative;
          top: 118px;
        }
      }
      .img-container.sticky{
        img{
          @media (max-height: 700px) {
            margin-top: -100px !important;
          }
        }
      }
    }
    .near-you{
      @media (min-width: 769px){
        padding-top: 32px;
        padding-bottom: 80px;
      }
      .text-container{
        @media (min-width: 769px){
          position: relative;
          top: 118px;
        }
      }
      .img-container.sticky{
        img{
          @media (max-height: 760px) {
            margin-top: -50px !important;
          }
          @media (max-height: 730px) {
            margin-top: -100px !important;
          }
          @media (max-height: 700px) {
            margin-top: -150px !important;
          }
          @media (max-height: 600px){
            margin-top: -220px !important;
          }
        }
      }
    }
  }
}

