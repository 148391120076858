:root {
  --mainColor: #2B5266;
  --whiteBgc: #FFFFFF;
  --lightGrey: #9D9D9C;
  --lightBlueBgc: #C9DAE5;
  --transparent: rgba(0,0,0,0);
}

@import '../layouts/two-cols.css';

.home {
  .intro-home{
    width: 100%;
    height: 500px;
    position: relative;
    top: 0;
    overflow: hidden;
    @media (max-width: 768px){
      height: 430px;
    }
    @media (max-width: 767px){
      height: auto;
    }
    @media (min-width: 1024px) and (max-height: 700px){
      height: 450px;
    }
    @media (min-width: 1024px) and (max-height: 650px){
      height: 400px;
    }
    .mask{
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      bottom: 0;
      margin: auto;
      overflow: hidden;
      @media (max-width: 767px){
        position: relative;
        top: auto;
        left: auto;
        right: auto;
        bottom: auto;
        height: 280px;
      }
      @media (max-width: 480px){
        height: 360px;
      }
      @media (max-width: 320px){
        height: 275px;
      }
      .image-container{
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        overflow: hidden;
        background-image: linear-gradient(-180deg, #E59E82 0%, #E5B786 8%, #DD8D69 92%, #D98968 100%);
        @media (max-width: 767px){
          height: 100%;
        }
        @media (max-width: 767px){
          height: 100%;
        }
        img {
          width: 100%;
          height: 100%;
          -o-object-fit: cover;
          object-fit: contain;
          display: flex;
          -o-object-position: center;
          object-position: 78% 50%;
          display: block;
          transform: scale(1.2) translate(8%,-11%);
          @supports (position: sticky) {
            transform: scale(1.6) translateY(-14%);
            @media (max-width: 1280px){
              object-position: 85% 50%;
              transform: scale(1.5) translateY(-12%);
            }
            @media (max-width: 1170px){
              object-position: 88% 50%;
              transform: scale(1.5) translateY(-15%);
            }
            @media (max-width: 1024px){
              object-position: 103% 50%;
              transform: scale(1.5) translateY(-14%);
            }
            @media (max-width: 890px){
              object-position: right top;
              transform: scale(1) translate(18%, 0%);
            }
            @media (max-width: 768px){
              object-position: 118% 50%;
              transform: scale(1.5) translateY(-14%);
            }
            @media (max-width: 767px){
              object-position: center;
              transform: scale(1.2) translateY(0);
              object-fit: cover;
            }
          }
        }
        .marie-louise{
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 93.444%;
          margin: 2.7777% auto;
          border: 5px solid #FFF;
          @media (max-width: 1024px){
            border-width: 4px;
            margin: 3.8% auto;
          }
          @media (max-width: 768px){
            border-width: 3px;
            margin: 2.8% auto;
            width: 94.4%;
          }
          @media (max-width: 480px){
            width: 88.4%;
            margin: 5.8% auto;
          }
        }
      }
    }
  }
  .inside-marie-louise{
    width: 93.2%;
    margin: 2.7777% auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    @media (max-width: 1024px){
      margin: 3.8% auto;
    }
    @media (max-width: 768px){
      margin: 2.8% auto;
      width: 94.2%;
    }
    @media (max-width: 767px){
      position: relative;
      width: auto;
      border: none;
      top: auto;
      left: auto;
      right: auto;
      bottom: auto;
      margin: 0;
    }
  }
  .two-cols{
    @media (max-width: 767px){
      flex-direction: initial;
    }
    @media (max-width: 767px){
      flex-direction: column;
    }
  }
  .category-item{
    background: #fff;
    min-height: 420px;
    width: 50%;
    @media (min-width: 1024px) and (max-height: 650px){
      min-height: 350px;
    }
    @media screen and (max-width: 768px){
      min-height: 390px;
    }
    @media screen and (max-width: 767px){
      width: 100%;
      min-height: 1px;
    }
    &.cat-item-1{
      position: absolute;
      height: 100%;
      min-height: 0;
      @media screen and (max-width: 767px){
        position: relative;
        height: auto;
      }
      .inner{
        padding: 5.223% 14.92% 5.671% 7.462%;
        position: absolute;
        left: 0;
        top: 0;
        @media (min-width: 1024px) and (max-height: 650px){
          padding: 3.223% 14.92% 3.671% 7.462%;
        }
        @media screen and (max-width: 1024px){
          padding: 9.3% 8.92% 8.671% 8.2%;
        }
        @media screen and (max-width: 768px){
          padding: 9% 8% 11% 3.5%;
        }
        @media screen and (max-width: 767px){
          padding: 7% 8% 7% 6%;
          position: relative;
          top: auto;
          left: auto;
        }
        @media screen and (max-width: 480px){
          padding: 40px 30px;
        }
      }
    }
    &.green{
      background: #E4ECF2;
      .inner{
        padding-left: 13.7%;
        @media screen and (max-width: 1024px){
          padding-left: 14.5%;
        }
        @media screen and (max-width: 768px){
          padding-left: 5.8%;
        }
        @media screen and (max-width: 767px){
          padding-left: 6%;
        }
        @media screen and (max-width: 480px){
          padding-left: 30px;
        }
        .inner-content{
          .cat-number{
            color: #fff;
          }
        }
      }
    }
    .inner{
      padding: 8.955% 14.92% 7.462% 7.4%;
      height: 100%;
      position: relative;
      @media screen and (max-width: 1160px){
        padding: 8.955% 9.92% 7.462% 6.5%
      }
      @media screen and (max-width: 1024px){
        padding: 13.2% 6.92% 9.462% 8.2%;
      }
      @media screen and (max-width: 768px){
        padding: 17% 4% 17% 6.5%;
      }
      @media screen and (max-width: 767px){
        padding: 7% 8% 7% 6%;
      }
      @media screen and (max-width: 480px){
        padding: 40px 30px;
      }
      @media (min-width: 1024px) and (max-height: 700px){
        padding: 7.955% 14.92% 7.462% 7.4%;
      }
      @media (min-width: 1024px) and (max-height: 650px){
        padding: 5.955% 14.92% 7.462% 7.4%;
      }
      .inner-content{
        position: relative;
        height: 100%;
        @media screen and (max-width: 767px){
          height: auto;
        }
        .cat-number{
          position: absolute;
          top: -25px;
          right: 0;
          margin-right: -30%;
          font-size: 160px;
          letter-spacing: 1.8px;
          line-height: 1em;
          color: #e4ecf2;
          opacity: .6;
          font-family: 'Libre Baskerville';
          @media (min-width: 1024px) and (max-height: 650px){
            font-size: 130px;
            top: -15px;
          }
          @media screen and (max-width: 1240px){
            font-size: 150px;
            top: -20px;
            margin-right: -20%;
          }
          @media screen and (max-width: 1160px){
            font-size: 140px;
            margin-right: -16%;
            top: -20px;
          }
          @media screen and (max-width: 1024px){
            font-size: 130px;
          }
          @media screen and (max-width: 960px){
            font-size: 120px;
            top: -21px;
            margin-right: 0;
          }
          @media screen and (max-width: 768px){
            font-size: 110px;
          }
          @media screen and (max-width: 480px){
            top: -35px;
            right: -20%;
            font-size: 140px;
          }
          @media screen and (max-width: 320px){
            font-size: 120px;
            top: -17px;
            right: -22%;
          }
        }
        h1{
          display: inline-block;
          position: relative;
          font-size: 32px;
          line-height: 39px;
          padding: 17px 0 35px 0;
          font-family: 'Montserrat', sans-serif;
          color: #285066;
          letter-spacing: 0;
          font-weight: 300;
          @media screen and (max-width: 1220px){
            font-size: 31px;
            line-height: 35px;
          }
          @media screen and (max-width: 1160px){
            font-size: 30px;
            line-height: 36px;
          }
          @media screen and (max-width: 1024px){
            font-size: 26px;
            line-height: 32px;
          }
          @media screen and (max-width: 960px){
            display: block;
            font-size: 24px;
            line-height: 30px;
          }
          @media screen and (max-width: 768px){
            font-size: 22px;
            line-height: 27px;
            padding-bottom: 28px;
          }
          @media screen and (max-width: 480px){
            display: inline-block;
            font-size: 24px;
            line-height: 30px;
            padding-bottom: 42px;
            min-width: 85%;
          }
          @media screen and (max-width: 380px){
            font-size: 20px;
            line-height: 26px;
          }
          @media screen and (max-width: 320px){
            font-size: 18px;
            line-height: 24px;
            padding-bottom: 29px;
          }
          @media (min-width: 1024px) and (max-height: 700px){
            padding: 17px 0 17px 0;
          }
          @media (min-width: 1024px) and (max-height: 650px){
            padding: 15px 0 15px 0;
            font-size: 28px;
          }
          .text{
            position: relative;
            z-index: 2;
          }
          .bold{
            font-weight: 600;
          }
        }
        p{
          font-family: Baskerville;
          font-size: 18px;
          color: #000000;
          line-height: 33px;
          margin-bottom: 0;
          .bold{
            font-weight: 600;
          }
          @media screen and (max-width: 1024px){
            font-size: 16px;
            line-height: 31px;
          }
          @media screen and (max-width: 768px){
            font-size: 14px;
            line-height: 29px;
          }
          @media screen and (max-width: 767px){
            margin-bottom: 20px;
          }
          @media screen and (max-width: 480px){
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 30px;
          }
          @media screen and (max-width: 320px){
            font-size: 12px;
            line-height: 22px;
            margin-bottom: 22px;
          }
          @media (min-width: 1024px) and (max-height: 700px){
            line-height: 30px;
          }
          @media (min-width: 1024px) and (max-height: 650px){
            font-size: 16px;
            line-height: 25px;
          }
          
        }
        a.btn{
          position: absolute;
          left: 0;
          bottom: 0;
          @media screen and (max-width: 767px){
            position: relative;
            left: auto;
            bottom: auto;
          }
        }
      }
    }
  }
}

.home{
  .ie{
    .intro-home{
      .mask{
        .image-container{
          img{
            transform: scale(1) translate(21%, -13%);
            @media (max-width: 1024px){
              transform: scale(1) translate(20%, -7%);
            }
          }
        }
      }
    }
  }
}

